<template>
  <v-card>
    <v-card-title>
      Pending Transactions
    </v-card-title>
    <v-row
      justify="center"
      class="mb-0"
    >
      <v-col class="col-md-6 text-center">
        <search-input
          v-model="search"
          :filters="searchFilters"
          :active-filter="activeSearchFilter"
          @filter-change="onSearchFilterChange"
        />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :loading="isTransactionsLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:progress>
        <v-progress-linear
          indeterminate
          class="mt-2"
          rounded
        ></v-progress-linear>
        <p class="text-center mt-1">
          Loading...Please wait
        </p>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="transactions.length > 0">
          <tr
            v-for="transaction in items"
            :key="transaction.transaction_reference"
          >
            <td>{{ transaction.reseller_name }}</td>
            <td>{{ transaction.meter_station_name }}</td>
            <td>{{ transaction.phone_number }}</td>
            <td>Ksh {{ transaction.amount | formatCurrency }}</td>
            <td>{{ transaction.transaction_time | formatDate }}</td>
            <td @click.stop>
              <v-btn
                class="ma-2"
                small
                depressed
                color="green"
                @click="approveTransaction(transaction.id)"
              >
                <v-icon
                  left
                  size="16"
                >
                  {{ icons.mdiCheck }}
                </v-icon>
                Approve
              </v-btn>
              <v-btn
                class="ma-2"
                small
                depressed
                color="red"
                @click="rejectTransaction(transaction.id)"
              >
                <v-icon
                  left
                  size="16"
                >
                  {{ icons.mdiClose }}
                </v-icon>
                Reject
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-show="!isTransactionsLoading">
            <td
              :colspan="headers.length"
              style="text-align: center"
            >
              No transactions found
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-divider />
    <data-table-pagination
      :page="pagination.page"
      :page-count="pagination.pageCount"
      @page-change="onPageChange"
      @items-per-page-change="onItemsPerPageChange"
    />
    <confirm-dialog
      :show-dialog="showConfirmDialog"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :agree-text="confirmDialogAgreeText"
      :is-agree-button-loading="confirmDialogAgreeButtonLoading"
      @agree="onConfirmDialogAgree"
      @cancel="onConfirmDialogCancel"
    />
  </v-card>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiCheck, mdiClose,
} from '@mdi/js'
import SearchInput from '@/components/partials/SearchInput.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    SearchInput,
    DataTablePagination,
    ConfirmDialog,
  },
  props: {},
  data() {
    return {
      sortBy: ['reseller_transaction_histories.created_at'],
      sortDesc: [true],
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        { text: 'Reseller', value: 'reseller_name' },
        { text: 'Station', value: 'meter_station_name' },
        { text: 'Phone Number', value: 'phone_number' },
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'transaction_time' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'reseller_transaction_histories.reseller_name',
      searchFilters: [
        {
          text: 'Reseller',
          value: 'reseller_transaction_histories.reseller_name',
        },
        {
          text: 'Amount',
          value: 'reseller_transaction_histories.amount',
        },
        {
          text: 'Date',
          value: 'reseller_transaction_histories.transaction_time',
        },
      ],
      icons: {
        mdiCheck,
        mdiClose,
      },
      isTransactionsLoading: false,
      transactions: [],
      showConfirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
      confirmDialogAgreeText: '',
      confirmDialogAgreeButtonLoading: false,
      transactionToActUpon: null,
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getTransactions()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getTransactions()
      }
    },
  },
  mounted() {
    this.getTransactions()
  },
  methods: {
    getTransactions: _.debounce(function () {
      this.isTransactionsLoading = true
      axios
        .get(`reseller/pending-transactions?sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`)
        .then(response => {
          this.transactions = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isTransactionsLoading = false
        })
        .catch(error => {
          this.isTransactionsLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    approveTransaction(id) {
      this.transactionToActUpon = id
      this.confirmDialogTitle = 'Confirm Approval'
      this.confirmDialogMessage = 'Are you sure you want to approve this transaction?'
      this.confirmDialogAgreeText = 'Approve'
      this.showConfirmDialog = true
    },
    rejectTransaction(id) {
      this.transactionToActUpon = id
      this.confirmDialogTitle = 'Confirm Rejection'
      this.confirmDialogMessage = 'Are you sure you want to reject this transaction?'
      this.confirmDialogAgreeText = 'Reject'
      this.showConfirmDialog = true
    },
    onConfirmDialogAgree() {
      this.confirmDialogAgreeButtonLoading = true

      axios
        .put(`reseller/update-transaction-status/${this.transactionToActUpon}`, {
          transaction_status: this.confirmDialogAgreeText === 'Approve' ? 1 : 2,
        })
        .then(() => {
        // Reset everything after successful transaction update
          this.confirmDialogAgreeButtonLoading = false
          this.showConfirmDialog = false
          this.transactionToActUpon = null

          this.getTransactions()
        })
        .catch(error => {
          console.error(error)

          this.$notification.error(error.response.data.message)
          this.confirmDialogAgreeButtonLoading = false
          this.showConfirmDialog = false
        })
    },
    onConfirmDialogCancel() {
      this.showConfirmDialog = false
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getTransactions()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getTransactions()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
  },
}
</script>
